import type { NewTag, Tag, TagResourceType } from "@fscrypto/domain/tag";
import { schema } from "@fscrypto/domain/tag";
import { Client, HttpClient } from "@fscrypto/http";
import { $path } from "remix-routes";
import { z } from "zod";

export class TagsClient {
  constructor(private client: HttpClient) {}

  get(resourceType: TagResourceType, resourceId: string) {
    return this.client.get<{ tags: Tag[] }>(
      $path("/api/tags/:resourceId", { resourceId }, { resourceType }),
      z.object({ tags: z.array(schema) }),
    );
  }

  getSuggested(resourceType: TagResourceType, resourceId: string) {
    return this.client.get<{ tags: Tag[] }>(
      $path("/api/tags/:resourceId", { resourceId }, { resourceType, suggested: "true" }),
      z.object({ tags: z.array(schema) }),
    );
  }

  setOnResource(resourceType: TagResourceType, resourceId: string, tagIds: string[]) {
    return this.client.post<{ createdCount: number; deletedCount: number }>(
      $path("/api/tags/:resourceId", { resourceId }, { resourceType }),
      { tagIds },
      z.object({ createdCount: z.number(), deletedCount: z.number() }),
    );
  }

  create(payload: NewTag) {
    return this.client.post<Tag>($path("/api/tags/create"), payload, schema);
  }

  search(search: string) {
    return this.client.get<Tag[]>($path("/api/tags", { search }), z.array(schema));
  }
}

export const tagsClient = new TagsClient(new Client());
